import React from "react"
import "./AffiliatesUpdateTop.scss"
import classNames from "classnames"
import bgImageAS from "./images/bgImage.png"
import bgImageASMd from "./images/bgImageMd.png"
import bgImageASLg from "./images/bgImageLg.png"
import bgImageSB from "./images/bgImageSB.png"
import bgImageSBMd from "./images/bgImageSBMd.png"
import bgImageSBLg from "./images/bgImageSBLg.png"
import {useTranslation} from "react-i18next"

const BG_IMAGES_BY_BRAND = {
  Allsports: {
    bgImage: bgImageAS,
    bgImageMd: bgImageASMd,
    bgImageLg: bgImageASLg,
  },
  SportBenefit: {
    bgImage: bgImageSB,
    bgImageMd: bgImageSBMd,
    bgImageLg: bgImageSBLg,
  }
}

function AffiliatesUpdateTop({ type, brand }) {
  const { t } = useTranslation()
  const texts = {
    title: t("Affiliates Update {{brand}}", { brand }),
    subtitle: t("Updates and changes in {{brand}} services", { brand }),
  }
  const { title, subtitle } = {...texts}

  return (
    <section className={classNames("affiliates-top", type)}>
      <h1 hidden>{title}</h1>
      {subtitle && <h2 className="top-text">{subtitle}</h2>}
      <div className="bg-wrapper">
        <img className="affiliates-top-bg"
          srcSet={
            BG_IMAGES_BY_BRAND[brand].bgImage +
            " 320w," +
            BG_IMAGES_BY_BRAND[brand].bgImageMd +
            " 768w," +
            BG_IMAGES_BY_BRAND[brand].bgImageLg +
            " 1024w,"
          }
          src={BG_IMAGES_BY_BRAND[brand].bgImage}
          sizes="(min-width: 320px) 320w,
            (min-width: 768px) 768w,
            (min-width: 1024px) 1024w,
            768w"
          alt="background" />
        <div className="bg-circle-large"></div>
        <div className="bg-circle-gray1"></div>
        <div className="bg-circle-gray2"></div>
        <div className="bg-circle-gray3"></div>
        <div className="bg-circle-blue1"></div>
      </div>
    </section>
  )
}

export default AffiliatesUpdateTop
