import React from "react"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import AffiliatesUpdateTop from "../../components/affiliatesUpdate/AffiliatesUpdateTop/AffiliatesUpdateTop"
import AffiliatesList from "../../components/affiliatesUpdate/AffiliatesList/AffiliatesList"

import { graphql, useStaticQuery } from "gatsby"

import mockData from "../../utils/affiliates-updates/2021-09-15"

const affiliatesArray = mockData;

const AffiliatesUpdateMain = ({ location }) => {

  const data = useStaticQuery(graphql`
    query affiliatesUpdatePageBy {
      site {
        siteMetadata {
          senderPath
          countries {
            by {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      hotLine={data.site.siteMetadata.countries.by.hotLine}
      location={location}
      country="by"
    >
      <Head
        lang="by"
        title="Обновления услуг AllSports"
        description="Обновления и изменения услуг AllSports"
        slug="/affiliates-update/"
      />
      <AffiliatesUpdateTop type="affiliates" brand={"Allsports"}/>
      <AffiliatesList affiliatesArray={affiliatesArray} />
    </Layout>
  )
}

export default AffiliatesUpdateMain
