import React from "react"
import "./AffiliatesList.scss"
import { isEmpty } from "lodash"
import regional from "../../../images/levels/region.png"
import silver from "../../../images/levels/silver.png"
import gold from "../../../images/levels/gold.png"
import platinum from "../../../images/levels/platinum.png"
import vip from "../../../images/levels/vip.png"

function renderCardBlock(cardName, imgSrc, index) {
  return (
    <div className="card-wrapper" key={cardName+index}>
      <img src={imgSrc} className="card-img" alt={cardName + ' card'} />
      <div className="card-text">{cardName}</div>
    </div>
  )
}

function showCards(cardName, index) {
  switch (cardName) {
    case 'regional':
    case 'region':
      return renderCardBlock(cardName, regional, index);
    case 'silver':
      return renderCardBlock(cardName, silver, index);
    case 'gold':
      return renderCardBlock(cardName, gold, index);
    case 'platinum':
      return renderCardBlock(cardName, platinum, index);
    case 'vip':
      return renderCardBlock(cardName, vip, index);
    default: return null;
  }
}

function AffiliatesList({ affiliatesArray }) {
  return (
    <section className="affiliates-list">
      {affiliatesArray.map((item, i) => {
        return (
          <div className="affiliate-card" key={item.key + i}>

            {!isEmpty(item.title) &&
              (<p className="title-text">{item.title}
                {!isEmpty(item.boldTitle) &&
                  <span className="important-text">&nbsp;{item.boldTitle}</span>}
              </p>)
            }

            {!isEmpty(item.cards) &&
              (<div className="cards-wrapper">
                {item.cards.map((cardName, i) => showCards(cardName, i))}
              </div>)
            }

            <div className="description-text">
              {!isEmpty(item.suppliers) &&
                item.suppliers.map((supplier, n) => {
                  return (
                    <div className="supplier-card" key={item.key+i+n}>
                      <div className="supplier-row">
                        <div className="supplier-logo">
                          <div className="logo"></div>
                        </div>
                        <div className="supplier-info">
                          <div className="supplier-name">{supplier.name}</div>
                          <div className="supplier-address">{supplier.address}</div>
                        </div>
                        <div className="supplier-text">{supplier.text}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default AffiliatesList
